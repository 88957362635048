//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import {
  required,
  email,
  minLength,
  sameAs,
} from "vuelidate/lib/validators";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "users/getField",
  mutationType: "users/updateField",
});

export default {
  name: "SignUp",
  data() {
    return {
      waiting: false,
      avatarPic: null,
      img: require("../assets/default.png"),
      loading: false,
      message: null,
      isPwd: true,
      genderOptions: ["Feminino", "Masculino", "Outros"],
      ageRangeOptions: [
        "Menos de 20 anos",
        "Entre 20 e 30 anos",
        "Entre 30 e 40 anos",
        "Entre 40 e 50 anos",
        "Entre 50 e 60 anos",
        "Mais de 60 anos",
      ],
      educationOptions: [
        "Fundamental completo",
        "Ensino médio completo",
        "Superior incompleto",
        "Superior completo",
        "Pós-graduado",
      ],
      selected: null,
    };
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(2),
    },
    lastName: {
      required,
      minLength: minLength(2),
    },
    email: {
      required,
      email
    },
    emailConfirmation: {
      required,
      sameAsPassword: sameAs("email"),
    },
    password: {
      required,
      minLength: minLength(8)
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
    gender: {
      required,
    },
    ageRange: {
      required,
    },
    education: {
      required,
    },
    avatarPic : {
    },
  },
  computed: {
    ...mapFields({
      firstName: "signUpForm.firstName",
      lastName: "signUpForm.lastName",
      email: "signUpForm.email",
      emailConfirmation: "signUpForm.emailConfirmation",
      password: "signUpForm.password",
      confirmPassword: "signUpForm.confirmPassword",
      categoryId: "signUpForm.categoryId",
      gender: "signUpForm.gender",
      otherGender: "signUpForm.otherGender",
      ageRange: "signUpForm.ageRange",
      education: "signUpForm.education",
      avatarId: "signUpForm.avatarId",
    }),
    ...mapGetters({
      options: "categories/loadCategories",
    }),
    avatar: {
      get() {
        if (this.img === null)
          return this.$store.getters["services/getDefaultImage"];
        return this.img;
      },
      set(url) {
        this.img = url;
      },
    },
    formIsValid() {
      if (this.$v.$anyError || this.selected === null) {
        return false;
      }
      return true;
    },
    firstNameErrorMessage() {
      if (!this.$v.firstName.required) {
        return "Preenchimento obrigatório";
      }
      if (!this.$v.firstName.minLength) {
        return "Mínimo de dois dígitos";
      }
      return "Preenchimento inválido"
    },
    lastNameErrorMessage() {
      if (!this.$v.lastName.required) {
        return "Preenchimento obrigatório";
      }
      if (!this.$v.lastName.minLength) {
        return "Mínimo de dois dígitos";
      }
      return "Preenchimento inválido"
    },
    emailErrorMessage() {
      if (!this.$v.email.required) {
        return "Email é requerido";
      }
      if (!this.$v.email.email) {
        return "Por favor insira um email válido";
      }
      return "Preenchimento inválido"
    },
    emailConfirmationErrorMessage() {
      if (!this.$v.emailConfirmation.required) {
        return "Confirmação de email é obrigatória";
      } else if (!this.$v.emailConfirmation.sameAsEmail) {
        return "Email não confere";
      }
      return "Preenchimento inválido"
    },
    passwordErrorMessage() {
      if (!this.$v.password.required) {
        return "Senha é obrigatória";
      }
      if (!this.$v.password.minLength) {
        return "Mínimo de 8 dígitos";
      }

      return "Preenchimento inválido"
    },
    confirmPasswordErrorMessage() {
      if (!this.$v.confirmPassword.required) {
        return "Confirmação de senha é obrigatória";
      } else if (!this.$v.confirmPassword.sameAsPassword) {
        return "Senha não confere";
      }
      return "Preenchimento inválido"
    },
    genderErrorMessage() {
      if (!this.$v.gender.required) {
        return "Informe seu gênero";
      }
      return "Preenchimento inválido"
    },
    ageRangeErrorMessage() {
      if (!this.$v.ageRange.required) {
        return "Informe sua faixa etária";
      }
      return "Preenchimento inválido"
    },
    educationErrorMessage() {
      if (!this.$v.education.required) {
        return "Informe seu grau de escolaridade";
      }
      return "Preenchimento inválido"
    },
    isNotOtherGender() {
      return this.gender != this.genderOptions[2];
    }
  },
  updated() {
    if (this.isNotOtherGender == true) {
      this.otherGender = "";
    }
  },
  methods: {
    async encode64() {
      await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.avatarPic);
        reader.onload = () => {
          this.avatar = reader.result;
        };
        reader.onerror = (error) => reject(error);
      });
    },
    selectCategory({id, color}) {
      this.categoryId = id;
      this.selected = true;
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$anyError && this.selected) {
        if (this.waiting) {
          this.$q.notify({
            message: "Por favor, aguarde.",
            position: 'top-right',
          });
        }
        this.waiting = true;
        this.loading = true;
        this.$store
          .dispatch("users/signUp", { file: this.avatarPic })
          .then(() => {

            this.firstName = ""
            this.lastName = ""
            this.email = ""
            this.emailConfirmation = ""
            this.password = ""
            this.confirmPassword = ""
            this.categoryId = ""
            this.gender = ""
            this.otherGender = ""
            this.ageRange = ""
            this.education = ""
            this.avatarId = ""

            this.$router.push({ name: "SignIn" });
            this.waiting = false;
          })
          .catch(() => this.waiting = false);
      } else {
        this.$q.notify({
          message: "Por favor, preencha os campos corretamente.",
          position: 'top-right',
        });
      }
    },
  },
};
